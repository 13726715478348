<template>
  <div>
    <CCollapse :show="true" :duration="400" v-for="(data,index) in years">
      <hr>
      <CButton @click="innerCollapse = innerCollapse === index ? false : index" size="mg" color="info">
        {{ data.title }}
      </CButton>
      <CCollapse :show="innerCollapse === index" class="mt-2">
        <h4>Details</h4>
        <table class="table table-bordered">
          <tr>
            <th>Team Size</th>
            <td>{{ data.detail.teamSize }}</td>
          </tr>
          <tr>
            <th>Description</th>
            <td>{{ data.detail.summary }}</td>
          </tr>
        </table>
        <div v-show="data.detail.skills.length > 0">
          <h4>Skills</h4>
          <table class="table table-bordered">
            <tr>
              <td>
                <span v-for="(data,index) in data.detail.skills">{{ data.title }},</span>
              </td>
            </tr>
          </table>
        </div>
        <div v-show="data.detail.certificates.length > 0">
          <h4>Certificates</h4>
          <table class="table table-bordered">
            <tr>
              <th>Title</th>
              <th>Issue Date</th>
              <th>Description</th>
              <th>URL</th>
            </tr>
            <tr v-for="(data) in data.detail.certificates">
              <td>{{ data.title }}</td>
              <td>{{ data.issueDate }}</td>
              <td>{{ data.description }}</td>
              <td><a :href="data.url">
                <CButton color="primary" v-c-tooltip="$lang.buttons.general.view"><i class="fas fa-eye"></i></CButton>
              </a></td>
            </tr>
          </table>
        </div>
        <div v-show="data.detail.awards.length > 0">
          <h4>Awards</h4>
          <table class="table table-bordered">
            <tr>
              <th>Title</th>
              <th>Issue Date</th>
              <th>Description</th>
              <th>URL</th>
            </tr>
            <tr v-for="(data,index) in data.detail.awards">
              <td>{{ data.title }}</td>
              <td>{{ data.issueDate }}</td>
              <td>{{ data.description }}</td>
              <td><a :href="data.url">
                <CButton color="primary" v-c-tooltip="$lang.buttons.general.view"><i class="fas fa-eye"></i></CButton>
              </a></td>
            </tr>
          </table>
        </div>
      </CCollapse>
    </CCollapse>
  </div>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import {profile} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import VueToasted from "vue-toasted";

function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

Vue.use(VueToasted);
const optionss = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};
Vue.use(VueNotifications, optionss);
Vue.use(VueForm, options);

export default {
  name: "DetailProfileTab",
  mixins: [Mixin],
  components: {},
  data() {
    return {
      collapse: false,
      cardCollapse: true,
      innerCollapse: false,
      years: [],
      self: this,
      module: profile,
    };
  },
  mounted() {
    let self = this;
    const id = this.$route.params.id;
    const url = 'users/journey/year/list/' + id;
    axios.post(url).then((response) => {
      response.data.data.map(function (value, key) {
        self.years.push(value);
      });
    });
  },
  methods: {},
};
</script>
