<template>
  <div class="detail-div">
    <v-server-table
        :columns="columns"
        :url="listUrl"
        :options="options"
        ref="myTable"
        @loaded="exports(self, 'Excel', module, 'Branch')"
    >
      <template #branchName="data">
      <span :title="data.row.branchName">{{
          trimfunction(data.row.branchName, 20)
        }}</span>
      </template>
      <template #companyName="data">
      <span :title="data.row.companyName">{{
          trimfunction(data.row.companyName, 20)
        }}</span>
      </template>
      <template #address="data">
      <span :title="data.row.address">{{
          trimfunction(data.row.address, 60)
        }}</span>
      </template>

    </v-server-table>
  </div>
</template>
<script>
import {branch} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";

export default {
  name: "BrancheTab",
  mixins: [Mixin],
  data() {
    return {
      listUrl: '/branch/list',
      self: this,
      columns: ["branchName", "isHeadOffice", "address", "locationChangeCount"],
      module: branch,
      options: {
        headings: {
          branchName: this.$lang.branch.table.branchName,
          isHeadOffice: this.$lang.branch.table.isHeadOffice,
          address: this.$lang.branch.table.address,
          locationChangeCount: this.$lang.branch.table.locationChangeCount,
          // actions: this.$lang.common.general.actions,
        },
        editableColumns: [
          "branchName",
          "isHeadOffice",
          "address",
        ],
        sortable: ["branchName", "isHeadOffice", "address"],
        filterable: ["branchName", "isHeadOffice", "address"],
      },
    };
  },
  created() {
    let self = this;

    self.dismissCountDown = 0;

    if (this.$route.params.id) {
      self.userId = this.$route.params.id;
    } else if (this.$route.params.user_id) {
      self.userId = this.$route.params.user_id;
    }
    self.listUrl = self.listUrl + "?userId=" + self.userId;
    self.dismissCountDown = 0;
  },
  methods: {},
};
</script>
